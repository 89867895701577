import { Dispatch, ReactElement } from 'react';
import { Text } from '@sede-x/shell-ds-react-framework';
import tableEditOptions from '../../../api/TableEditOptions';
import {
  I_ALIGNE_TABLE,
  I_GROUP_TABLE,
  ENDUR_I_GAS_TABLE,
  NUCLEUS_I_POWER_TABLE,
  ENDUR_SEAM_REGION_SCOPE_TABLE,
  NUCLEUS_SEAM_REGION_SCOPE_TABLE,
  COUNTRY_CODE,
  COUNTRY_NAME,
  OUT_OF_SCOPE,
  IN_SCOPE,
  FIVE_THOUSAND,
  THOUSAND,
  ELEVEN,
  TWENTY_SIX,
  COMMENTS,
  SCOPE,
  BUYSELL,
  DEAL_QUANTITY,
  MARKET,
  NCF_CATEGORY,
  QTY,
  ID,
  ASIA_JAPAN_SHORT_NAME,
  ENDURAU_STATIC_MAP_NCF,
  VOLUME,
  NCF_SCOPE,
} from '../../../api/constants';
import {
  fetchCountryForSeamRegionTableEdit,
  fetchMarketOptions,
  fetchNcfCategoriesForAsiaAndAmericaRegion,
  fetchNcfCategoriesForAsiaAndAmericaRegionV1,
  fetchNcfCategoriesOptions,
  fetchReport,
} from '../../../api/services';
import { showSpinner, hideSpinner } from '../../Utils/utils';
import { Action, IEditFeatureData } from '../../../types/types';
import { AxiosResponse } from 'axios';
import React from 'react';

// Function setEditableColumnNames - set editable columns for selected table
const setEditableColumnNames = (
  state: any,
  dispatch: Dispatch<Action>,
  cols: any
) => {
  const editableColumn =
    state.tableSelected !== ''
      ? (tableEditOptions[
          state.tableSelected as keyof typeof tableEditOptions
        ] as {
          editableFields: string[];
        }).editableFields
      : [];
  //console statement
  const colEdit = [];
  for (const editColumn of editableColumn) {
    for (const col of cols) {
      const editableColumnVal = editColumn.toLowerCase().replace('_', '');
      const colsVal = col.toLowerCase().replace('_', '');
      if (editableColumnVal === colsVal) {
        colEdit.push(col);
      }
    }
  }
  //console statement
  dispatch({ type: 'setEditableCol', payload: colEdit });
};

// Function handleOnChange - to fetch pagination data on page chages in the table
export const handleOnChange = async (
  current: number,
  dispatch: Dispatch<Action>,
  state: { filteredOptions: any; dirtyData: any },
  isEdit: boolean,
  isReadyToPublish: boolean,
  ID1: string
): Promise<void> => {
  dispatch({ type: 'setCurrentPaginationNum', payload: current });
  showSpinner(dispatch);

  const options = { ...state.filteredOptions, pageNumber: current };

  try {
    const viewReport = await fetchReport('get', options);

    if (viewReport?.data) {
      const masterPaginationData = JSON.parse(
        JSON.stringify(viewReport.data.records)
      );
      dispatch({
        type: 'setMasterPaginationData',
        payload: masterPaginationData,
      });
      hideSpinner(dispatch);
      setUpPagination(
        isEdit,
        isReadyToPublish,
        viewReport,
        dispatch,
        state,
        ID1
      );
    }
  } catch (e) {
    hideSpinner(dispatch);
  }
};
//END of Pagination

const setPaginationData = (data: any, dispatch: Dispatch<Action>) => {
  dispatch({ type: 'setPaginationData', payload: data });
};

const setUpPagination = (
  isEdit: boolean,
  isReadyToPublish: boolean,
  viewReport: AxiosResponse,
  dispatch: Dispatch<Action>,
  state: { filteredOptions: any; dirtyData: any },
  ID1: string
) => {
  if (!isEdit && !isReadyToPublish) {
    // Set pagination data from pagination controls during non-Edit mode
    setPaginationData(viewReport.data.records, dispatch);
    return;
  }
  const records = viewReport.data.records;
  //for edit flow , whenever user has changed data on different pages and user goes to that page
  //compare api response with dirty data and restore the changes user had done previously
  //so that the view will have the changes user had done
  for (const dirtyData of state.dirtyData) {
    const matchedRecord = records.find(
      (record: { [x: string]: any }) => dirtyData[ID1] === record[ID1]
    );
    if (matchedRecord) {
      const dirtyKeys = Object.keys(dirtyData).slice(1);
      for (const key of dirtyKeys) {
        matchedRecord[key] = dirtyData[key];
      }
    }
  }
  setPaginationData(records, dispatch);
};

export const getColumnNames = (columns: string[], cols: any) => {
  const [
    ID,
    QTY,
    DEAL_QUANTITY,
    MARKET,
    NCF_CATEGORY,
    BUYSELL,
    COMMENTS,
    SCOPE,
    COUNTRY_NAME,
    VOLUME,
    NCF_SCOPE,
  ] = columns;
  const IdColWithoutSplChar = ID.toLowerCase().replace('_', '');
  const QtyColWithoutSplChar = QTY.toLowerCase().replace('_', '');
  const DealQtyColWithoutSplChar = DEAL_QUANTITY.toLowerCase().replace('_', '');
  const MarketColWithoutSplChar = MARKET.toLowerCase().replace('_', '');
  const NcfCtgryColWithoutSplChar = NCF_CATEGORY.toLowerCase().replace('_', '');
  const BuySellColWithoutSplChar = BUYSELL.toLowerCase().replace('_', '');
  const CommentsColWithoutSplChar = COMMENTS.toLowerCase().replace('_', '');
  const ScopeColWithoutSplChar = SCOPE.toLowerCase().replace('_', '');
  const VolumeNameWithoutSplChar = VOLUME.toLowerCase().replace('_', '');
  const NcfScopeWithoutSplChar = NCF_SCOPE.toLowerCase().replace('_', '');
  const CountryNameWithoutSplChar = COUNTRY_NAME
    ? COUNTRY_NAME.toLowerCase().replace('_', '')
    : '';
  const columnMappings: { [key: string]: string } = {
    [IdColWithoutSplChar]: 'ID1',
    [QtyColWithoutSplChar]: 'QTY1',
    [DealQtyColWithoutSplChar]: 'DEAL_QUANTITY1',
    [MarketColWithoutSplChar]: 'MARKET1',
    [NcfCtgryColWithoutSplChar]: 'NCF_CATEGORY1',
    [BuySellColWithoutSplChar]: 'BUYSELL1',
    [CommentsColWithoutSplChar]: 'COMMENTS1',
    [ScopeColWithoutSplChar]: 'SCOPE1',
    [CountryNameWithoutSplChar]: 'COUNTRY_NAME1',
    [VolumeNameWithoutSplChar]: 'VOLUME1',
    [NcfScopeWithoutSplChar]: 'NCF_SCOPE1',
  };

  const result: { [key: string]: string } = {};

  for (const col of cols) {
    const colNameWithoutSpecialChars = col.toLowerCase().replace('_', '');

    if (columnMappings[colNameWithoutSpecialChars]) {
      result[columnMappings[colNameWithoutSpecialChars]] = col;
    }
  }

  // Ensure all keys have a value, defaulting to an empty string
  for (const key in columnMappings) {
    if (result[columnMappings[key]] === undefined) {
      result[columnMappings[key]] = '';
    }
  }

  return result;
};

const setUpEditFeatureForAustraliaTables = async (
  state: { tableSelected: string },
  dispatch: any,
  setIsServiceFailure: (arg0: boolean) => void
) => {
  if (state.tableSelected === ENDURAU_STATIC_MAP_NCF) {
    const ncfCatOption = [];
    showSpinner(dispatch);
    try {
      const ncfCat = await fetchNcfCategoriesForAsiaAndAmericaRegion('AU');
      const ncfCatOptns = Array.from(
        new Set(
          ncfCat.data.map((item: { NCF_CATEGORY: string }) => item.NCF_CATEGORY)
        )
      ).sort((a, b) => {
        // Explicitly cast a and b to string
        const stringA = a as string;
        const stringB = b as string;
        // Compare function to sort elements by their natural order
        if (stringA < stringB) return -1;
        if (stringA > stringB) return 1;
        return 0;
      });
      for (const ncfCatOpt of ncfCatOptns) {
        ncfCatOption.push({ value: ncfCatOpt, label: ncfCatOpt });
      }

      dispatch({ type: 'setNcfCategoryOptions', payload: ncfCatOption });
    } catch (error) {
      setIsServiceFailure(true);
    }
    hideSpinner(dispatch);
  }
};

const setUpEditFeatureForEuropeTables = async (
  state: { tableSelected: string },
  dispatch: any,
  setIsServiceFailure: (arg0: boolean) => void
) => {
  if ([I_ALIGNE_TABLE, I_GROUP_TABLE].includes(state.tableSelected)) {
    const marketOption = [];
    const ncfCatOption = [];
    showSpinner(dispatch);
    try {
      const market = await fetchMarketOptions(state.tableSelected);
      const ncfCat = await fetchNcfCategoriesOptions(state.tableSelected);
      const ncfCatOptns = ncfCat.data;
      const marketOptns = market.data;
      for (const ncfCatOpt of ncfCatOptns) {
        ncfCatOption.push({ value: ncfCatOpt, label: ncfCatOpt });
      }
      for (const marketOpt of marketOptns) {
        marketOption.push({ value: marketOpt, label: marketOpt });
      }
      dispatch({ type: 'setMarketOptions', payload: marketOption });
      dispatch({ type: 'setNcfCategoryOptions', payload: ncfCatOption });
    } catch (error) {
      setIsServiceFailure(true);
    }
    hideSpinner(dispatch);
  }
};

const setUpEditFeatureForEndureIgasAndNucleusIpowerTables = async (
  state: IEditFeatureData,
  dispatch: { (value: Action): void },
  setIsServiceFailure: {
    (value: React.SetStateAction<boolean>): void;
    (arg0: boolean): void;
  }
) => {
  //fetch ncf cat options for the below tables.
  if (
    [ENDUR_I_GAS_TABLE, NUCLEUS_I_POWER_TABLE].includes(state.tableSelected)
  ) {
    const ncfCatOption = [];
    showSpinner(dispatch);
    try {
      const ncfCat = await fetchNcfCategoriesOptions(state.tableSelected);
      const ncfCatOptns = ncfCat.data;
      for (const ncfCatOpt of ncfCatOptns) {
        ncfCatOption.push({ value: ncfCatOpt, label: ncfCatOpt });
      }
      dispatch({ type: 'setNcfCategoryOptions', payload: ncfCatOption });
    } catch (error) {
      setIsServiceFailure(true);
    }
    hideSpinner(dispatch);
  }
};

const setUpEditFeatureForAmericasSeamRegionScopeTables = async (
  state: IEditFeatureData,
  dispatch: Dispatch<Action>,
  setRegionOptionsForAmericasSeamRegionTable: {
    (value: React.SetStateAction<{ label: string; value: string }[]>): void;
    (arg0: any): void;
  },
  setIsServiceFailure: {
    (value: React.SetStateAction<boolean>): void;
    (arg0: boolean): void;
  }
) => {
  if (
    [ENDUR_SEAM_REGION_SCOPE_TABLE, NUCLEUS_SEAM_REGION_SCOPE_TABLE].includes(
      state.tableSelected
    )
  ) {
    showSpinner(dispatch);
    try {
      const regionForSeamRegionTableEditResp = await fetchCountryForSeamRegionTableEdit(
        'Americas'
      );
      const regionForSeamRegionTableEditOptions =
        regionForSeamRegionTableEditResp.data;
      setRegionOptionsForAmericasSeamRegionTable(
        regionForSeamRegionTableEditOptions
      );
    } catch (error) {
      setIsServiceFailure(true);
    }
    hideSpinner(dispatch);
  }
};

const setUpEditFeatureForAsiaJapanTables = async (
  state: IEditFeatureData,
  dispatch: Dispatch<Action>,
  setNcfCatOptionsForAsiaJapanTable: {
    (value: React.SetStateAction<{ label: string; value: string }[]>): void;
    (arg0: { label: any; value: any }[]): void;
  },
  setIsServiceFailure: {
    (value: React.SetStateAction<boolean>): void;
    (arg0: boolean): void;
  }
) => {
  if (state.filteredRegion.toUpperCase() === ASIA_JAPAN_SHORT_NAME) {
    const regionForAsiaJapanTableEditOptns = [];
    showSpinner(dispatch);
    try {
      const regionForAsiaJapanTablesResp = await fetchNcfCategoriesForAsiaAndAmericaRegionV1(
        state.filteredRegion
      );
      const regionForAsiaJapanTableEditOptions = regionForAsiaJapanTablesResp.data
        .map((item: { NCF_CATEGORY: any }) => item.NCF_CATEGORY)
        .filter(
          (value: any, index: any, self: string | any[]) =>
            self.indexOf(value) === index
        );
      for (const tableEditOption of regionForAsiaJapanTableEditOptions) {
        regionForAsiaJapanTableEditOptns.push({
          label: tableEditOption,
          value: tableEditOption,
        });
      }
      setNcfCatOptionsForAsiaJapanTable(regionForAsiaJapanTableEditOptns);
    } catch (error) {
      setIsServiceFailure(true);
    }
    hideSpinner(dispatch);
  }
};

// Function handleEdit - is called whenever an editable field is modified
export const handleEdit = async (options: any) => {
  const {
    setNcfCatOptionsForAsiaJapanTable,
    setRegionOptionsForAmericasSeamRegionTable,
    setIsEdit,
    setIsShowEditBtn,
    state,
    dispatch,
    setIsServiceFailure,
    cols,
  } = options;
  setUpEditFeatureForEuropeTables(state, dispatch, setIsServiceFailure);
  setUpEditFeatureForEndureIgasAndNucleusIpowerTables(
    state,
    dispatch,
    setIsServiceFailure
  );
  setUpEditFeatureForAmericasSeamRegionScopeTables(
    state,
    dispatch,
    setRegionOptionsForAmericasSeamRegionTable,
    setIsServiceFailure
  );
  setUpEditFeatureForAsiaJapanTables(
    state,
    dispatch,
    setNcfCatOptionsForAsiaJapanTable,
    setIsServiceFailure
  );
  setUpEditFeatureForAustraliaTables(state, dispatch, setIsServiceFailure);

  setEditableColumnNames(state, dispatch, cols);
  setIsEdit(true);
  setIsShowEditBtn(false);
};

export const isQtyValid = (value: number | string) => {
  let isQtyZero = false;
  let isQtyWholeNumberUpto25Digits = true;
  let isQtyDecimalUpto10Digits = true;
  let isQtyExceedsMaxLength = false;
  const pattern = /^-?\d{1,25}(\.\d{1,10})?$/;

  if (typeof value === 'number') {
    isQtyZero = value === 0;
    if (!isQtyZero) {
      isQtyExceedsMaxLength = !pattern.test(value.toString());
    }
  } else {
    isQtyZero = value === '0' || value === '';
    if (!isQtyZero) {
      isQtyWholeNumberUpto25Digits = value.split('.')[0].length < TWENTY_SIX;
      isQtyDecimalUpto10Digits = value.split('.')[1]
        ? value.split('.')[1].length < ELEVEN
        : true;
    }
  }
  const isQtyInvalid =
    isQtyZero ||
    !isQtyWholeNumberUpto25Digits ||
    !isQtyDecimalUpto10Digits ||
    isQtyExceedsMaxLength;

  return !isQtyInvalid;
};

// Function handleValAndSave - is called when button 'Validate And Save' is clicked
export const handleValAndSave = (handleValAndSaveOptions: any) => {
  const {
    state,
    isEdit,
    isReadyToPublish,
    ID1,
    setIsDirtyData,
    setErrorTableData,
    isDirtyData,
    COMMENTS1,
    setIsReadyToPublish,
    dispatch,
    setIsEditError,
    setIsEdit,
    setIsShowPublishBtn,
  } = handleValAndSaveOptions;
  let isValidData = true;
  let isdirtyData = isDirtyData;
  const currDirtyData = [...state.dirtyData];
  const currErrData = [...state.erroredRows];

  // check if any error data still present when HANDLE and SAVE is clicked.
  isValidData = currErrData.length === 0;

  // check if any UI error, if so throw respective error messages
  handleErrorMessageScenarios(state, dispatch);
  //check dirtyData must have COMMENTS column for all the dirty row entries
  if (isValidData) {
    // check this only if validation is true. If validation is already false, no need to check as the error mesasge will already be thrown
    for (const data of currDirtyData) {
      //if COMMENTS is not present, validation will fail and break out of the for loop
      if (!Object.hasOwn(data, COMMENTS1)) {
        isValidData = false;
        break;
      }
      isValidData = true;
    }
  }
  isdirtyData = currDirtyData.length > 0;

  if (!isdirtyData) {
    // if no dirty data at all
    setIsDirtyData(isdirtyData);

    if (state.publishRows.length > 0) {
      dispatch({ type: 'setPaginationData', payload: state.publishRows });
      setIsReadyToPublish(true);
    } else {
      handleOnChange(
        state.currentPaginationNum,
        dispatch,
        state,
        isEdit,
        isReadyToPublish,
        ID1
      );
      setIsEditError(false);
      setIsReadyToPublish(false);
    }
  } else if (isdirtyData && isValidData) {
    setIsReadyToPublish(true);
    //show updated table data and clear all dirty data as all data is finalized to be published and no dirty data left.
    dispatch({ type: 'setPaginationData', payload: state.publishRows });
    setIsEditError(false);
    setIsEdit(false);
    setIsShowPublishBtn(true);
    //setting mode to Publish
    setIsReadyToPublish(true);
  } else {
    //show errored columns only
    dispatch({ type: 'setPaginationData', payload: currErrData });
    setErrorTableData(currErrData);
    setIsEditError(true);
  }
};

const handleErrorMessageScenarios = (
  state: IEditFeatureData,
  dispatch: Dispatch<Action>
) => {
  const currErrData = [...state.erroredRows];
  const masterErroredData = [...state.masterErroredRows];
  const differences: {
    id: number;
    columnName: string[];
    isCommentsValid: boolean;
    isQuantityValid: boolean;
    isCountryValid: boolean;
  }[] = [];
  const colNames = getColumnNames(
    [
      ID,
      QTY,
      DEAL_QUANTITY,
      MARKET,
      NCF_CATEGORY,
      BUYSELL,
      COMMENTS,
      SCOPE,
      COUNTRY_NAME,
      VOLUME,
      NCF_SCOPE,
    ],
    Object.keys(masterErroredData[0])
  );
  const {
    ID1,
    QTY1,
    DEAL_QUANTITY1,
    MARKET1,
    NCF_CATEGORY1,
    BUYSELL1,
    COMMENTS1,
    SCOPE1,
    COUNTRY_NAME1,
    VOLUME1,
    NCF_SCOPE1,
  } = colNames;
  const editableColumns = [
    QTY1,
    DEAL_QUANTITY1,
    MARKET1,
    NCF_CATEGORY1,
    BUYSELL1,
    SCOPE1,
    COUNTRY_NAME1,
    VOLUME1,
    NCF_SCOPE1,
  ];
  for (const masterData of masterErroredData) {
    for (const erroredRow of currErrData) {
      processErroredData(
        masterData,
        erroredRow,
        {
          ID1,
          COMMENTS1,
          COUNTRY_NAME1,
          SCOPE1,
          QTY1,
          DEAL_QUANTITY1,
          VOLUME1,
          NCF_SCOPE1,
        },
        editableColumns,
        differences
      );
    }
  }
  dispatch({
    type: 'setIsAnyInvalidComment',
    payload: differences.some(data => !data.isCommentsValid),
  });
  dispatch({
    type: 'setIsAnyInvalidCountryName',
    payload: differences.some(data => !data.isCountryValid),
  });
  dispatch({
    type: 'setIsAnyInvalidQuantity',
    payload: differences.some(data => !data.isQuantityValid),
  });
};

const processErroredData = (
  masterData: { [x: string]: string | number },
  erroredRow: { [x: string]: string | number },
  colNames: {
    ID1: string;
    COMMENTS1: string;
    COUNTRY_NAME1: string;
    SCOPE1: string;
    QTY1: string;
    DEAL_QUANTITY1: string;
    VOLUME1: string;
    NCF_SCOPE1: string;
  },
  editableColumns: string | string[],
  differences: {
    id: string | number;
    columnName: string[];
    isCommentsValid: boolean;
    isQuantityValid: boolean;
    isCountryValid: boolean;
  }[]
) => {
  const changedCol: string[] = [];
  const {
    ID1,
    COMMENTS1,
    COUNTRY_NAME1,
    SCOPE1,
    QTY1,
    DEAL_QUANTITY1,
    VOLUME1,
    NCF_SCOPE1,
  } = colNames;
  const isCommentsModifiedAndNonEmpty = true;
  const isQuantityValid = true;
  const isCountryValid = true;
  for (const key in masterData) {
    if (masterData[ID1] === erroredRow[ID1] && editableColumns.includes(key)) {
      const params = {
        isCommentsModifiedAndNonEmpty,
        isQuantityValid,
        isCountryValid,
        masterData,
        erroredRow,
        changedCol,
        key,
        SCOPE1,
        COMMENTS1,
        differences,
        COUNTRY_NAME1,
        ID1,
        QTY1,
        DEAL_QUANTITY1,
        VOLUME1,
        NCF_SCOPE1,
      };
      processErroredDataForEditableColumns(params);
    }
  }
};

const processErroredDataForEditableColumns = (params: {
  isCommentsModifiedAndNonEmpty: boolean;
  isQuantityValid: boolean;
  isCountryValid: boolean;
  masterData: { [x: string]: string | number };
  erroredRow: { [x: string]: string | number };
  changedCol: string[];
  key: string;
  SCOPE1: string;
  COMMENTS1: string;
  differences: {
    id: string | number;
    columnName: string[];
    isCommentsValid: boolean;
    isQuantityValid: boolean;
    isCountryValid: boolean;
  }[];
  COUNTRY_NAME1: string;
  ID1: string;
  QTY1: string;
  DEAL_QUANTITY1: string;
  VOLUME1: string;
  NCF_SCOPE1: string;
}) => {
  const {
    masterData,
    erroredRow,
    changedCol,
    key,
    SCOPE1,
    COMMENTS1,
    differences,
    COUNTRY_NAME1,
    ID1,
    QTY1,
    DEAL_QUANTITY1,
    VOLUME1,
  } = params;
  if (masterData[key] !== erroredRow[key]) {
    changedCol.push(key);
    params.isCommentsModifiedAndNonEmpty =
      masterData[COMMENTS1] !== erroredRow[COMMENTS1] &&
      erroredRow[COMMENTS1] !== '';
    const isDataPresentArray = differences.filter(
      data => data.id === masterData[ID1]
    );
    if ([QTY1, DEAL_QUANTITY1, VOLUME1].includes(key)) {
      params.isQuantityValid = isQtyValid(erroredRow[key]);
    }
    if ([COUNTRY_NAME1, SCOPE1].includes(key)) {
      params.isCountryValid = erroredRow[COUNTRY_NAME1] !== '';
    }
    // if id is present, then update the columnName key, else push new data
    if (isDataPresentArray.length > 0) {
      isDataPresentArray[0].columnName = changedCol;
    } else {
      differences.push({
        id: masterData[ID1],
        columnName: changedCol,
        isCommentsValid: params.isCommentsModifiedAndNonEmpty,
        isQuantityValid: params.isQuantityValid,
        isCountryValid: params.isCountryValid,
      });
    }
  }
};

// Funciton handlePublish - is called when PUBLISH button is clicked
export const handlePublish = async (handlePublishOptions: any) => {
  const {
    setIsShowDeploymentToPowerBiMessage,
    setIsPublishTableFailure,
    setIsPublished,
    state,
    ID1,
    COMMENTS1,
    MARKET1,
    BUYSELL1,
    QTY1,
    DEAL_QUANTITY1,
    NCF_CATEGORY1,
    SCOPE1,
    VOLUME1,
    NCF_SCOPE1,
    isScopeTableSelected,
    dispatch,
    setIsShowEditBtn,
    setLooseChanges,
    setOriginalVal,
    setUpdatedCol,
    setUpdatedVal,
    setIsShowPublishBtn,
    setIsReadyToPublish,
    setIsEdit,
    setIsEditError,
    setIsDirtyData,
    setIsEuropeTableDataValidated,
    setIsAmericasTableDataValidated,
  } = handlePublishOptions;
  const finalData = [...state.dirtyData];
  //console statement - final data
  //change column/key names as per the required
  const colnames = {
    ID1,
    COMMENTS1,
    MARKET1,
    BUYSELL1,
    QTY1,
    DEAL_QUANTITY1,
    NCF_CATEGORY1,
    SCOPE1,
    VOLUME1,
    NCF_SCOPE1,
  };
  const publishData = getPublishData(colnames, finalData, state);
  // for Americas's scope tables, convert scope value from string to integer.
  setScope(isScopeTableSelected, publishData);
  showSpinner(dispatch);
  const postData = {
    region: state.filteredOptions.region,
    tableName: state.tableSelected,
    updatedBy: state.mail,
    modifiedRecords: publishData,
  };
  const resetTableOptions = {
    setIsShowEditBtn,
    setLooseChanges,
    setOriginalVal,
    setUpdatedCol,
    setUpdatedVal,
    setIsShowPublishBtn,
    setIsReadyToPublish,
    dispatch,
    setIsEdit,
    setIsEditError,
    setIsDirtyData,
    setIsEuropeTableDataValidated,
    setIsAmericasTableDataValidated,
  };
  makePublishApi(
    resetTableOptions,
    postData,
    setIsPublished,
    state,
    setIsShowDeploymentToPowerBiMessage,
    setIsPublishTableFailure
  );
};

const getPublishData = (colnames: any, finalData: any, state: any) => {
  const {
    ID1,
    COMMENTS1,
    MARKET1,
    BUYSELL1,
    QTY1,
    DEAL_QUANTITY1,
    NCF_CATEGORY1,
    SCOPE1,
    VOLUME1,
    NCF_SCOPE1,
  } = colnames;
  return finalData.map((rec: { [x: string]: any }) => {
    const finalPublishData: any = {
      id: rec[ID1],
      comments: rec[COMMENTS1],
    };
    const propertiesToMap = [
      { key: 'market', value: MARKET1 },
      { key: 'buySell', value: BUYSELL1 },
      { key: 'qty', value: QTY1 },
      { key: 'qty', value: DEAL_QUANTITY1 },
      { key: 'ncfCategory', value: NCF_CATEGORY1 },
      { key: 'scope', value: SCOPE1 },
      { key: 'countryCode', value: COUNTRY_CODE },
      { key: 'countryName', value: COUNTRY_NAME },
      { key: 'qty', value: VOLUME1 },
      { key: 'scope', value: NCF_SCOPE1 },
    ];
    propertiesToMap.forEach(({ key, value }) => {
      if (rec[value] !== undefined) {
        finalPublishData[key] = rec[value];
      }
    });

    if (state.tableSelected === ENDURAU_STATIC_MAP_NCF) {
      updateFinalPublishData(finalPublishData, state.masterErroredRows);
    }
    return finalPublishData;
  });
};

type IPublishData = {
  id: number;
  comments?: string;
  scope?: string;
  ncfCategory?: string;
};

const updateFinalPublishData = (
  finalPublishData: IPublishData,
  masterErroredRows: any[]
): IPublishData  | false => {
  const masterRecord = masterErroredRows.find(
    (record: { Id: number }) => record.Id === finalPublishData.id
  );

  if (!masterRecord) {
    return false;
  }

  // If finalPublishData has only scope
  if ('scope' in finalPublishData && !('ncfCategory' in finalPublishData)) {
    finalPublishData.ncfCategory = masterRecord.NCF_CATEGORY;
  }

  // If finalPublishData has only ncfCategory
  if ('ncfCategory' in finalPublishData && !('scope' in finalPublishData)) {
    finalPublishData.scope = masterRecord.NCF_SCOPE;
  }

  return finalPublishData;
};

const setScope = (isScopeTableSelected: boolean, publishData: any) => {
  if (isScopeTableSelected) {
    const scopeMap = {
      [OUT_OF_SCOPE]: 0,
      [IN_SCOPE]: 1,
    };

    for (const obj of publishData) {
      if (obj.scope in scopeMap) {
        obj.scope = scopeMap[obj.scope as keyof typeof scopeMap];
      }
    }
  }
};
const makePublishApi = async (
  resetTableOptions: any,
  postData: any,
  setIsPublished: any,
  state: any,
  setIsShowDeploymentToPowerBiMessage: any,
  setIsPublishTableFailure: any
) => {
  const { dispatch } = resetTableOptions;
  try {
    await fetchReport('put', postData);
    setIsPublished(true);
    setTimeout(() => {
      setIsPublished(false);
    }, THOUSAND);
    resetTable(resetTableOptions);
    dispatch({ type: 'setHasTableChangesPublished', payload: true });

    //view or edit report page
    const options = state.filteredOptions;
    const viewReport = await fetchReport('get', options);
    dispatch({ type: 'setHasTableChangesPublished', payload: false });
    if (viewReport?.data) {
      dispatch({
        type: 'setPaginationData',
        payload: viewReport.data.records,
      });
      dispatch({
        type: 'setMasterPaginationData',
        payload: JSON.parse(JSON.stringify(viewReport.data.records)),
      });
      dispatch({ type: 'setRecordCount', payload: viewReport.data.count });
    }
    hideSpinner(dispatch);
    dispatch({ type: 'setShowReport', payload: true });

    setIsShowDeploymentToPowerBiMessage(true);
    setTimeout(() => {
      setIsShowDeploymentToPowerBiMessage(false);
    }, FIVE_THOUSAND);
  } catch (e) {
    setIsPublishTableFailure(true);
    hideSpinner(dispatch);
  }
};

// Function handleGetNewRecord - is called when button GET NEW REOCRD is clicked.
// This leverages the user to move out of current table view or table edit scenario
export const handleGetNewRecord = (handleGetNewRecordOptions: any) => {
  const { state, setLooseChanges } = handleGetNewRecordOptions;
  //If clicked during edit mode or publish mode, alert user to loose unsaved/unpublished data
  if (state.dirtyData.length > 0) {
    setLooseChanges(true);
  } else {
    handleConfirmNewRecord(handleGetNewRecordOptions);
  }
};

// Function resetTable - will reset all the state variables to their default values
const resetTable = (options: any) => {
  const {
    setIsShowEditBtn,
    setLooseChanges,
    setOriginalVal,
    setUpdatedCol,
    setUpdatedVal,
    setIsShowPublishBtn,
    setIsReadyToPublish,
    dispatch,
    setIsEdit,
    setIsEditError,
    setIsDirtyData,
  } = options;
  //reset the buttons
  setIsShowEditBtn(true);
  setIsShowPublishBtn(false);
  setIsReadyToPublish(false);
  //hide table
  dispatch({ type: 'setShowReport', payload: false });
  //enable Get Data button
  dispatch({ type: 'setIsGetData', payload: false });
  //empty all previos table data, pagination data and dirty data
  dispatch({ type: 'setPaginationData', payload: [] });
  dispatch({ type: 'setDirtyData', payload: [] });
  dispatch({ type: 'setMasterPaginationData', payload: [] });
  dispatch({ type: 'setErroredRows', payload: [] });
  dispatch({ type: 'setMasterErroredRows', payload: [] });
  dispatch({ type: 'setPublishRows', payload: [] });
  // set editable mode to false
  setIsEdit(false);
  setIsEditError(false);
  // reset variables
  setIsDirtyData(true);
  dispatch({ type: 'setIsAnyInvalidComment', payload: false });
  dispatch({
    type: 'setIsAnyInvalidCountryName',
    payload: false,
  });
  dispatch({ type: 'setIsAnyInvalidQuantity', payload: false });
  setLooseChanges(false);
  setOriginalVal('');
  setUpdatedCol('');
  setUpdatedVal('');
};

export const handleConfirmNewRecord = (handleGetNewRecordOptions: any) => {
  resetTable(handleGetNewRecordOptions);
};

export const handleCancelNewRecord = (setLooseChanges: {
  (value: React.SetStateAction<boolean>): void;
  (arg0: boolean): void;
}) => {
  setLooseChanges(false);
};

export const innerOnClose = (
  isPublishTableFailure: boolean,
  setIsServiceFailure: {
    (value: React.SetStateAction<boolean>): void;
    (arg0: boolean): void;
  },
  setIsPublishTableFailure: any
) => {
  if (isPublishTableFailure) {
    setIsPublishTableFailure(false);
  }
  setIsServiceFailure(false);
};

export const EmptyComponent = (): ReactElement => (
  <Text>
    <b>There&apos;s no record</b>
  </Text>
);
